import React, { Component } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown.js';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';

import DatePicker from 'react-datepicker';

// Detail Data Modal
import Modal from 'react-responsive-modal';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';

import 'react-datepicker/dist/react-datepicker.css';

// koneksi
import { Post, Get, Delete, cekLogin } from '../../../function/Koneksi';
import { DataToken } from "../../../function/Format";

// Import custom components
import Breadcrumb from '../../common/breadcrumb.component';

drilldown(Highcharts);

const { ExportCSVButton } = CSVExport;

class Default extends Component {
	constructor(props) {
		super(props);

		this.state = {
			penduduk: null,
			non_penduduk: null,
			detail_penduduk: null,
			tgl_produk_mulai: '',
			tgl_produk_selesai: '',
			produk_tahun: null,
			detail_produk_desa: null,
			tgl_transaksi_mulai: '',
			tgl_transaksi_selesai: '',
			transaksi_bulan: null,
			detail_transaksi: null,
			akun: DataToken(localStorage.getItem("access_token")),

			// =========== Data Potensi ========
			data_potensi_penduduk: [],

			// ============= Highcharts data ================
			data_agama: [],
			drilldown_data_agama: [],

			data_pekerjaan: [],
			drilldown_data_pekerjaan: [],

			data_usia: [],
			drilldown_data_usia: [],
			cek_data_usia: [],

			data_pernikahan: [],
			drilldown_data_pernikahan: [],

			data_air_minum: [],
			drilldown_data_air_minum: [],

			data_kepemilikan_rumah: [],
			drilldown_data_kepemilikan_rumah: [],

			data_kategori_usaha: [],
			drilldown_data_kategori_usaha: [],

			data_kepemilikan_lahan: [],
			drilldown_data_kepemilikan_lahan: [],

			data_detail_produksi: [],
			drilldown_detail_produksi: [],

			data_aset: [],
			drilldown_data_aset: [],

			data_lembaga_ekonomi: [],
			drilldown_data_lembaga_ekonomi: [],

			data_aset_pilihan: [],
			drilldown_data_aset_pilihan: [],

			data_produksi_galian: [],
			drilldown_data_produksi_galian: [],

			// ============= FILTER HIGH CHART ===============
			option_jenis_sumber_air: [],


			// Dropdown Desa
			id_dropdown: '',
			labelDropdown: '',
			dropdownData: [],

			// MODAL
			status: {
				dataPotensi: false
			},
			data_detail_sumber_air: [],
			filterValue: '',

		};
	}

	ChangeDropdown = (e) => {
		if (e) {
			this.setState({ id_dropdown: e.target.value });

			if (e.target.value == 'all') {
				this.setState({
					id_dropdown: '',
				});
				console.log("DATA ON CHANGE ALL BOS")
				console.log(e.target.value)
			}
			else {
				let idnya = e.target.value;
				console.log("DATA ON CHANGE")
				console.log(idnya)
			}
		}
		else {
			this.setState({ id_dropdown: '' });
		}

	}


	tutupForm = () => {
		this.state.status.dataPotensi = false;
		this.forceUpdate();
	};


	componentDidMount() {

		// console.log("CEK LEVEL USER BOS")
		// console.log(this.state.akun.sub.data_kabkota)
		// console.log(this.state.akun.sub.data_kecamatan)

		// DATA JUMLAH MASYARAKAT

		let id_fill = ''
		let role_log = ''
		if (this.state.akun.sub.data_kecamatan != null) {
			id_fill = this.state.akun.sub.data_kecamatan.id
			role_log = "kecamatan"

			// Dropdown Keldes
			Get('wilayah/desa/' + id_fill, null, (data) => {
				console.log("DATA KELDES KELURAHAN")
				console.log(data.results)
				this.setState({
					dropdownData: data.results,
					labelDropdown: 'Seluruh Data Kelurahan/Desa'
				});
			});
		}
		else {
			id_fill = this.state.akun.sub.data_kabkota.id
			role_log = "kabkota"

			// Dropdown Keldes
			Get('wilayah/kecamatan/' + id_fill, null, (data) => {
				console.log("DATA Kecamatan KABKOTA")
				console.log(data.results)
				this.setState({
					dropdownData: data.results,
					labelDropdown: 'Seluruh Data Kecamatan'
				});
			});
		}

		// Label Jumlah Masyarakat
		Get('data-grafik/' + role_log + '/' + id_fill + '/jumlah-masyarakat', null, (data) => {
			let data_potensi_penduduk = {
				total: data.result.total,
				laki_laki: data.result.laki_laki,
				perempuan: data.result.perempuan
			};

			this.setState({ data_potensi_penduduk });
		});

		// Highcharts data
		// Pie

		// CHART AGAMA MASYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/agama-masyarakat', null, (data) => {
			var data_agama = [];
			var drilldown_data_agama = []; // Fitur untuk detail

			for (var [index, agama] of data.result.entries()) {
				data_agama.push({
					name: agama.data_agama.nama,
					y: agama.total,
					drilldown: agama.data_agama.nama + index
				});
			}
			this.setState({ data_agama });
			this.setState({ drilldown_data_agama });
		});

		// CHART PEKERJAAN MASYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/pekerjaan-masyarakat', null, (data) => {
			var data_pekerjaan = [];
			var drilldown_data_pekerjaan = []; // Fitur untuk detail

			// if (typeof data.result != 'undefined') {
			for (var [index, pekerjaan] of data.result.entries()) {
				data_pekerjaan.push({
					name: pekerjaan.data_pekerjaan.nama,
					y: pekerjaan.total,
					drilldown: pekerjaan.data_pekerjaan.nama + index
				});
				// }
			}

			this.setState({ data_pekerjaan });
			this.setState({ drilldown_data_pekerjaan });
		});

		// CHART USIA MASYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/rentang-umur-masyarakat', null, (data) => {
			var data_usia = [];
			var drilldown_data_usia = []; // Fitur untuk detail

			data_usia.push({
				name: 'Balita (0-5 tahun)',
				y: data.result.balita,
				drilldown: 'balita'
			});
			data_usia.push({
				name: 'Kanak-kanak (5-11 tahun)',
				y: data.result.kanak_kanak,
				drilldown: 'kanak_kanak'
			});
			data_usia.push({
				name: 'Remaja Awal (12-16 tahun)',
				y: data.result.remaja_awal,
				drilldown: 'remaja_awal'
			});
			data_usia.push({
				name: 'Remaja Akhir (17-25 tahun)',
				y: data.result.remaja_akhir,
				drilldown: 'remaja_akhir'
			});
			data_usia.push({
				name: 'Dewasa Awal (26-35 tahun)',
				y: data.result.dewasa_awal,
				drilldown: 'dewasa_awal'
			});
			data_usia.push({
				name: 'Dewasa Akhir (36-45 tahun)',
				y: data.result.dewasa_akhir,
				drilldown: 'dewasa_akhir'
			});
			data_usia.push({
				name: 'Lansia Awal (46-55 tahun)',
				y: data.result.lansia_awal,
				drilldown: 'lansia_awal'
			});
			data_usia.push({
				name: 'Lansia Akhir (56-65 tahun)',
				y: data.result.lansia_akhir,
				drilldown: 'lansia_akhir'
			});
			data_usia.push({
				name: 'Manula (> 65 tahun)',
				y: data.result.manula,
				drilldown: 'manula'
			});

			this.setState({ data_usia });
			this.setState({ drilldown_data_usia });
		});


		// Donut

		// DATA PERNIKAHAN MASYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/pernikahan-masyarakat', null, (data) => {
			var data_pernikahan = [];
			var drilldown_data_pernikahan = []; // Fitur untuk detail

			for (var [index, data_nikah] of data.result.entries()) {
				data_pernikahan.push({
					name: data_nikah.status_perkawinan,
					y: data_nikah.total,
					drilldown: data_nikah.status_perkawinan + index
				});
			}

			this.setState({ data_pernikahan });
			this.setState({ drilldown_data_pernikahan });
		});

		// DATA SUMBER AIR MASYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/sumber-air-minum', null, (data) => {
			// console.log(data.result);
			var data_air_minum = [];
			var drilldown_data_air_minum = []; // Fitur untuk detail
			let option_jenis_sumber_air = [];

			for (var [index, air_minum] of data.result.entries()) {
				if (air_minum.data_sumber != null) {
					data_air_minum.push({
						name: air_minum.data_sumber.nama,
						y: air_minum.total,
						drilldown: air_minum.data_sumber.nama + index
					});
					option_jenis_sumber_air.push({
						value: air_minum.data_sumber.uuid,
						label: air_minum.data_sumber.nama
					});
				}
			}

			this.setState({ data_air_minum });
			this.setState({ drilldown_data_air_minum });
			this.setState({ option_jenis_sumber_air });
		});

		// DATA KEPEMILIKAN RUMAH
		Get('data-grafik/' + role_log + '/' + id_fill + '/kepemilikan-rumah', null, (data) => {
			var data_kepemilikan_rumah = [];
			var drilldown_data_kepemilikan_rumah = []; // Fitur untuk detail

			for (var [index, rumah] of data.result.entries()) {
				data_kepemilikan_rumah.push({
					name: rumah.data_sumber_kepemilikan.nama_status,
					y: rumah.total,
					drilldown: rumah.data_sumber_kepemilikan.nama_status + index
				});
			}

			this.setState({ data_kepemilikan_rumah });
			this.setState({ drilldown_data_kepemilikan_rumah });
		});


		// STATISTIK
		// KEPEMILIKAN LAHAN
		Get('data-grafik/' + role_log + '/' + id_fill + '/kepemilikan-lahan', null, (data) => {
			var data_kepemilikan_lahan = [];
			var drilldown_data_kepemilikan_lahan = [];

			for (var [index, jenis_lahan] of data.result.entries()) {
				var tmp_jumlah = 0;
				var sub_lahan = []; // Untuk drilldown
				for (var [index2, lahan] of jenis_lahan.child.entries()) {
					if (lahan.status != 'Tidak Memiliki') {
						sub_lahan.push([lahan.status, lahan.total]); // Untuk drilldown
						tmp_jumlah += lahan.total;
					}
				}

				// if (tmp_jumlah > 0) {
				data_kepemilikan_lahan.push({
					name: jenis_lahan.nama,
					y: tmp_jumlah,
					drilldown: jenis_lahan.nama + index
				});

				drilldown_data_kepemilikan_lahan.push({
					id: jenis_lahan.nama + index,
					// name: 'Total Kepemilikan ' + jenis_lahan.nama,
					name: 'Jumlah warga yang memiliki',
					data: sub_lahan
				});
				// }
			}

			this.setState({ data_kepemilikan_lahan });
			this.setState({ drilldown_data_kepemilikan_lahan });
		});

		// KOMODITAS MASYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/komoditas-masyarakat', null, (data) => {
			var data_detail_produksi = [];
			var drilldown_detail_produksi = [];

			// console.log("DATA KOMODITAS")
			// console.log(data.result)
			data.result.forEach((jenis_komoditas, index) => {
				data_detail_produksi.push({
					name: jenis_komoditas.nama,
					y: jenis_komoditas.total,
					drilldown: jenis_komoditas.nama + index
				});

				var drilldown2 = [];
				if (jenis_komoditas.child.length > 0) {
					jenis_komoditas.child.forEach((komoditas, index2) => {
						var drilldown3_id = komoditas.nama + index2;
						// set data series
						drilldown2.push([komoditas.nama, komoditas.total]);

					});
				}

				if (drilldown2.length > 0) {
					drilldown_detail_produksi.push({
						id: jenis_komoditas.nama + index,
						// name: 'Total Kepemilikan ' + jenis_komoditas.nama,
						name: 'Jumlah warga yang memiliki',
						data: drilldown2
					});
				}
			});

			this.setState({ data_detail_produksi });
			this.setState({ drilldown_detail_produksi });
		});

		// ASET MASYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/aset-masyarakat', null, (data) => {
			var data_aset = []; // data turunan petama
			var drilldown_data_aset = []; // data turunan kedua,dst

			for (var [index, kategori_aset] of data.result.entries()) {
				var tmp_jumlah = 0;
				var sub_aset = []; // Untuk drilldown
				for (var [index2, aset] of kategori_aset.child.entries()) {
					if (aset.nama != 'Tidak Memiliki') {
						sub_aset.push([aset.nama, aset.total]); // Untuk drilldown
						tmp_jumlah += aset.total;
					}
				}

				// if (tmp_jumlah > 0) {
				data_aset.push({
					name: kategori_aset.nama,
					y: tmp_jumlah,
					drilldown: kategori_aset.nama + index
				});

				drilldown_data_aset.push({
					id: kategori_aset.nama + index,
					// name: 'Total Kepemilikan ' + kategori_aset.nama,
					name: 'Jumlah warga yang memiliki',
					data: sub_aset
				});
				// }
			}

			this.setState({ data_aset });
			this.setState({ drilldown_data_aset });
		});


		// GALIAN
		Get('data-grafik/' + role_log + '/' + id_fill + '/galian', null, (data) => {
			var data_produksi_galian = [];
			var drilldown_data_produksi_galian = [];

			for (var [index, jenis_galian] of data.result.entries()) {
				var tmp_jumlah = 0;
				var sub_gali = []; // Untuk drilldown
				for (var [index2, kepemilikan] of jenis_galian.child.entries()) {
					if (kepemilikan.nama != 'Tidak Memiliki') {
						sub_gali.push([kepemilikan.nama, kepemilikan.total]); // Untuk drilldown
						tmp_jumlah += kepemilikan.total;
					}
				}

				// if (tmp_jumlah > 0) {
				data_produksi_galian.push({
					name: jenis_galian.nama,
					y: tmp_jumlah,
					drilldown: jenis_galian.nama + index
				});

				drilldown_data_produksi_galian.push({
					id: jenis_galian.nama + index,
					// name: 'Total Kepemilikan ' + jenis_galian.nama,
					name: 'Jumlah warga yang memiliki',
					data: sub_gali
				});
				// }
			}

			this.setState({
				data_produksi_galian,
				drilldown_data_produksi_galian
			});
		});


		// LEMBAGA EKONOMI MAsYARAKAT
		Get('data-grafik/' + role_log + '/' + id_fill + '/lembaga-ekonomi-masyarakat', null, (data) => {
			// console.log(data);
			var data_lembaga_ekonomi = [];

			if (typeof data.result != 'undefined') {
				for (var [index, jenis_lembaga_ekonomi] of data.result.entries()) {
					data_lembaga_ekonomi.push([jenis_lembaga_ekonomi.data_lembaga.nama, jenis_lembaga_ekonomi.total])
				}
			}

			this.setState({ data_lembaga_ekonomi });
		});

		// KATEGORI USAHA
		Get('data-grafik/' + role_log + '/' + id_fill + '/chart-kategori-usaha', null, (data) => {
			var data_kategori_usaha = [];
			// var drilldown_data_kategori_usaha = [];

			if (typeof data.result != 'undefined') {
				for (var [index, kategori] of data.result.entries()) {
					if (kategori.data_kategori_usaha != null) {
						data_kategori_usaha.push([kategori.data_kategori_usaha.kategori_usaha, kategori.jumlah]);
					}
					// else {
					// 	data_kategori_usaha.push({
					// 		name: 'Toko Pribadi',
					// 		y: kategori.jumlah,
					// 		drilldown: 'Toko Pribadi' + index
					// 	});
					// }
				}
			}
			this.setState({ data_kategori_usaha });
			// this.setState({ drilldown_data_kategori_usaha });
		});


		// console.log(localStorage.getItem('access_token'));
	}


	highchartPieFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'pie',
				height: 280
			},
			credits: {
				enabled: false
			},
			accessibility: {
				announceNewData: {
					enabled: true
				},
				point: {
					valueSuffix: '%'
				}
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						alignTo: 'plotEdges',
						connectorPadding: 0,
						connectorShape: 'crookedLine',
						distance: 20,
						overflow: 'allow',
						style: {
							fontSize: '9px'
						}
					}
					// showInLegend: true
				}
			},
			title: {
				text: title_chart,
				margin: 3,
				style: {
					fontSize: '13px',
					fontWeight: 'bold'
				}
			},
			legend: {
				enabled: true
			},
			series: [
				{
					name: 'Total',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	highchartDonutFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				// plotBackgroundColor: null,
				// plotBorderWidth: 0,
				plotShadow: false,
				height: 280
			},
			credits: {
				enabled: false
			},
			accessibility: {
				announceNewData: {
					enabled: true
				},
				point: {
					valueSuffix: '%'
				}
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						alignTo: 'plotEdges',
						connectorPadding: 0,
						connectorShape: 'crookedLine',
						distance: 10,
						style: {
							fontSize: '9px'
						}
					}
				}
			},
			title: {
				text: title_chart,
				margin: 3,
				style: {
					fontSize: '13px',
					fontWeight: 'bold'
				},
				align: 'center'
			},
			legend: {
				enabled: true
			},
			series: [
				{
					name: 'Total',
					type: 'pie',
					innerSize: '60%',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	highchartStatisticFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'column'
			},
			credits: {
				enabled: false
			},
			title: {
				text: title_chart
			},
			xAxis: {
				type: 'category'
			},
			legend: {
				enabled: false
			},
			series: [
				{
					name: 'Jumlah warga yang memiliki',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

	onSubmitDropdown = (e) => {
		e.preventDefault();

		let id_fill = ''
		let role_log = ''

		if (this.state.akun.sub.data_kecamatan != null) {
			id_fill = this.state.akun.sub.data_kecamatan.id
			role_log = "kecamatan"

			// Dropdown Keldes
			Get('wilayah/desa/' + id_fill, null, (data) => {
				console.log("DATA KELDES KELURAHAN")
				console.log(data.results)
				this.setState({
					dropdownData: data.results,
					labelDropdown: 'Seluruh Data Kelurahan/Desa'
				});
			});
		}
		else {
			id_fill = this.state.akun.sub.data_kabkota.id
			role_log = "kabkota"

			// Dropdown Keldes
			Get('wilayah/kecamatan/' + id_fill, null, (data) => {
				console.log("DATA Kecamatan KABKOTA")
				console.log(data.results)
				this.setState({
					dropdownData: data.results,
					labelDropdown: 'Seluruh Data Kecamatan'
				});
			});
		}

		let cek_id_submit = this.state.id_dropdown
		let id_submit = ''
		let set_role_submit = ''

		if (cek_id_submit == 'all' || cek_id_submit == '<empty string>' || cek_id_submit == 'undefined' || cek_id_submit == '') {
			console.log("Set ALL Data Bos")

			set_role_submit = role_log
			id_submit = id_fill
		}
		else {
			console.log("Set Dropdown Bos")
			console.log(cek_id_submit)

			if (role_log == "kecamatan") {
				set_role_submit = "desa"
				id_submit = this.state.id_dropdown
			}
			else if (role_log == "kabkota") {
				set_role_submit = "kecamatan"
				id_submit = this.state.id_dropdown
			}
		}

		// Label Jumlah Masyarakat
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/jumlah-masyarakat', null, (data) => {
			let data_potensi_penduduk = {
				total: data.result.total,
				laki_laki: data.result.laki_laki,
				perempuan: data.result.perempuan
			};

			this.setState({ data_potensi_penduduk });
		});

		// Highcharts data
		// Pie

		// CHART AGAMA MASYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/agama-masyarakat', null, (data) => {
			var data_agama = [];
			var drilldown_data_agama = []; // Fitur untuk detail

			for (var [index, agama] of data.result.entries()) {
				data_agama.push({
					name: agama.data_agama.nama,
					y: agama.total,
					drilldown: agama.data_agama.nama + index
				});
			}
			this.setState({ data_agama });
			this.setState({ drilldown_data_agama });
		});

		// CHART PEKERJAAN MASYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/pekerjaan-masyarakat', null, (data) => {
			var data_pekerjaan = [];
			var drilldown_data_pekerjaan = []; // Fitur untuk detail

			// if (typeof data.result != 'undefined') {
			for (var [index, pekerjaan] of data.result.entries()) {
				data_pekerjaan.push({
					name: pekerjaan.data_pekerjaan.nama,
					y: pekerjaan.total,
					drilldown: pekerjaan.data_pekerjaan.nama + index
				});
				// }
			}

			this.setState({ data_pekerjaan });
			this.setState({ drilldown_data_pekerjaan });
		});

		// CHART USIA MASYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/rentang-umur-masyarakat', null, (data) => {
			var data_usia = [];
			var drilldown_data_usia = []; // Fitur untuk detail

			this.setState({ cek_data_usia: data.result })

			data_usia.push({
				name: 'Balita (0-5 tahun)',
				y: data.result.balita,
				drilldown: 'balita'
			});
			data_usia.push({
				name: 'Kanak-kanak (5-11 tahun)',
				y: data.result.kanak_kanak,
				drilldown: 'kanak_kanak'
			});
			data_usia.push({
				name: 'Remaja Awal (12-16 tahun)',
				y: data.result.remaja_awal,
				drilldown: 'remaja_awal'
			});
			data_usia.push({
				name: 'Remaja Akhir (17-25 tahun)',
				y: data.result.remaja_akhir,
				drilldown: 'remaja_akhir'
			});
			data_usia.push({
				name: 'Dewasa Awal (26-35 tahun)',
				y: data.result.dewasa_awal,
				drilldown: 'dewasa_awal'
			});
			data_usia.push({
				name: 'Dewasa Akhir (36-45 tahun)',
				y: data.result.dewasa_akhir,
				drilldown: 'dewasa_akhir'
			});
			data_usia.push({
				name: 'Lansia Awal (46-55 tahun)',
				y: data.result.lansia_awal,
				drilldown: 'lansia_awal'
			});
			data_usia.push({
				name: 'Lansia Akhir (56-65 tahun)',
				y: data.result.lansia_akhir,
				drilldown: 'lansia_akhir'
			});
			data_usia.push({
				name: 'Manula (> 65 tahun)',
				y: data.result.manula,
				drilldown: 'manula'
			});

			this.setState({ data_usia });
			this.setState({ drilldown_data_usia });
		});


		// Donut

		// DATA PERNIKAHAN MASYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/pernikahan-masyarakat', null, (data) => {
			var data_pernikahan = [];
			var drilldown_data_pernikahan = []; // Fitur untuk detail

			for (var [index, data_nikah] of data.result.entries()) {
				data_pernikahan.push({
					name: data_nikah.status_perkawinan,
					y: data_nikah.total,
					drilldown: data_nikah.status_perkawinan + index
				});
			}

			this.setState({ data_pernikahan });
			this.setState({ drilldown_data_pernikahan });
		});

		// DATA SUMBER AIR MASYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/sumber-air-minum', null, (data) => {
			// console.log(data.result);
			var data_air_minum = [];
			var drilldown_data_air_minum = []; // Fitur untuk detail
			let option_jenis_sumber_air = [];

			for (var [index, air_minum] of data.result.entries()) {
				if (air_minum.data_sumber != null) {
					data_air_minum.push({
						name: air_minum.data_sumber.nama,
						y: air_minum.total,
						drilldown: air_minum.data_sumber.nama + index
					});
					option_jenis_sumber_air.push({
						value: air_minum.data_sumber.uuid,
						label: air_minum.data_sumber.nama
					});
				}
			}

			this.setState({ data_air_minum });
			this.setState({ drilldown_data_air_minum });
			this.setState({ option_jenis_sumber_air });
		});

		// DATA KEPEMILIKAN RUMAH
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/kepemilikan-rumah', null, (data) => {
			var data_kepemilikan_rumah = [];
			var drilldown_data_kepemilikan_rumah = []; // Fitur untuk detail

			for (var [index, rumah] of data.result.entries()) {
				data_kepemilikan_rumah.push({
					name: rumah.data_sumber_kepemilikan.nama_status,
					y: rumah.total,
					drilldown: rumah.data_sumber_kepemilikan.nama_status + index
				});
			}

			this.setState({ data_kepemilikan_rumah });
			this.setState({ drilldown_data_kepemilikan_rumah });
		});


		// STATISTIK
		// KEPEMILIKAN LAHAN
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/kepemilikan-lahan', null, (data) => {
			var data_kepemilikan_lahan = [];
			var drilldown_data_kepemilikan_lahan = [];

			for (var [index, jenis_lahan] of data.result.entries()) {
				var tmp_jumlah = 0;
				var sub_lahan = []; // Untuk drilldown
				for (var [index2, lahan] of jenis_lahan.child.entries()) {
					if (lahan.status != 'Tidak Memiliki') {
						sub_lahan.push([lahan.status, lahan.total]); // Untuk drilldown
						tmp_jumlah += lahan.total;
					}
				}

				// if (tmp_jumlah > 0) {
				data_kepemilikan_lahan.push({
					name: jenis_lahan.nama,
					y: tmp_jumlah,
					drilldown: jenis_lahan.nama + index
				});

				drilldown_data_kepemilikan_lahan.push({
					id: jenis_lahan.nama + index,
					// name: 'Total Kepemilikan ' + jenis_lahan.nama,
					name: 'Jumlah warga yang memiliki',
					data: sub_lahan
				});
				// }
			}

			this.setState({ data_kepemilikan_lahan });
			this.setState({ drilldown_data_kepemilikan_lahan });
		});

		// KOMODITAS MASYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/komoditas-masyarakat', null, (data) => {
			var data_detail_produksi = [];
			var drilldown_detail_produksi = [];

			// console.log("DATA KOMODITAS")
			// console.log(data.result)
			data.result.forEach((jenis_komoditas, index) => {
				data_detail_produksi.push({
					name: jenis_komoditas.nama,
					y: jenis_komoditas.total,
					drilldown: jenis_komoditas.nama + index
				});

				var drilldown2 = [];
				if (jenis_komoditas.child.length > 0) {
					jenis_komoditas.child.forEach((komoditas, index2) => {
						var drilldown3_id = komoditas.nama + index2;
						// set data series
						drilldown2.push([komoditas.nama, komoditas.total]);

					});
				}

				if (drilldown2.length > 0) {
					drilldown_detail_produksi.push({
						id: jenis_komoditas.nama + index,
						// name: 'Total Kepemilikan ' + jenis_komoditas.nama,
						name: 'Jumlah warga yang memiliki',
						data: drilldown2
					});
				}
			});

			this.setState({ data_detail_produksi });
			this.setState({ drilldown_detail_produksi });
		});

		// ASET MASYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/aset-masyarakat', null, (data) => {
			var data_aset = []; // data turunan petama
			var drilldown_data_aset = []; // data turunan kedua,dst

			for (var [index, kategori_aset] of data.result.entries()) {
				var tmp_jumlah = 0;
				var sub_aset = []; // Untuk drilldown
				for (var [index2, aset] of kategori_aset.child.entries()) {
					if (aset.nama != 'Tidak Memiliki') {
						sub_aset.push([aset.nama, aset.total]); // Untuk drilldown
						tmp_jumlah += aset.total;
					}
				}

				// if (tmp_jumlah > 0) {
				data_aset.push({
					name: kategori_aset.nama,
					y: tmp_jumlah,
					drilldown: kategori_aset.nama + index
				});

				drilldown_data_aset.push({
					id: kategori_aset.nama + index,
					// name: 'Total Kepemilikan ' + kategori_aset.nama,
					name: 'Jumlah warga yang memiliki',
					data: sub_aset
				});
				// }
			}

			this.setState({ data_aset });
			this.setState({ drilldown_data_aset });
		});


		// GALIAN
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/galian', null, (data) => {
			var data_produksi_galian = [];
			var drilldown_data_produksi_galian = [];

			for (var [index, jenis_galian] of data.result.entries()) {
				var tmp_jumlah = 0;
				var sub_gali = []; // Untuk drilldown
				for (var [index2, kepemilikan] of jenis_galian.child.entries()) {
					if (kepemilikan.nama != 'Tidak Memiliki') {
						sub_gali.push([kepemilikan.nama, kepemilikan.total]); // Untuk drilldown
						tmp_jumlah += kepemilikan.total;
					}
				}

				// if (tmp_jumlah > 0) {
				data_produksi_galian.push({
					name: jenis_galian.nama,
					y: tmp_jumlah,
					drilldown: jenis_galian.nama + index
				});

				drilldown_data_produksi_galian.push({
					id: jenis_galian.nama + index,
					// name: 'Total Kepemilikan ' + jenis_galian.nama,
					name: 'Jumlah warga yang memiliki',
					data: sub_gali
				});
				// }
			}

			this.setState({
				data_produksi_galian,
				drilldown_data_produksi_galian
			});
		});


		// LEMBAGA EKONOMI MAsYARAKAT
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/lembaga-ekonomi-masyarakat', null, (data) => {
			// console.log(data);
			var data_lembaga_ekonomi = [];

			if (typeof data.result != 'undefined') {
				for (var [index, jenis_lembaga_ekonomi] of data.result.entries()) {
					data_lembaga_ekonomi.push([jenis_lembaga_ekonomi.data_lembaga.nama, jenis_lembaga_ekonomi.total])
				}
			}

			this.setState({ data_lembaga_ekonomi });
		});

		// KATEGORI USAHA
		Get('data-grafik/' + set_role_submit + '/' + id_submit + '/chart-kategori-usaha', null, (data) => {
			var data_kategori_usaha = [];
			// var drilldown_data_kategori_usaha = [];

			if (typeof data.result != 'undefined') {
				for (var [index, kategori] of data.result.entries()) {
					if (kategori.data_kategori_usaha != null) {
						data_kategori_usaha.push([kategori.data_kategori_usaha.kategori_usaha, kategori.jumlah]);
					}
					// else {
					// 	data_kategori_usaha.push({
					// 		name: 'Toko Pribadi',
					// 		y: kategori.jumlah,
					// 		drilldown: 'Toko Pribadi' + index
					// 	});
					// }
				}
			}
			this.setState({ data_kategori_usaha });
			// this.setState({ drilldown_data_kategori_usaha });
		});

		console.log("SHOW SUBMIT DATA")
		console.log("ID KELDES: ")
		console.log(id_submit)

		/* -----------you would send data to API to get results, I used database for ease, this also clears the form on submit----------------*/
	}

	getDataSumberAir = (table) => {
		let data_detail_sumber_air = [];
		let id_fill = this.state.akun.sub.data_kecamatan.id
		let role_log = "kecamatan"

		Get('data-grafik/' + role_log + '/' + id_fill + '/sumber-air-minum-masyarakat', null, (data) => {
			// console.log(data);
			if (typeof data.result != 'undefined') {
				data.result.map((dt, index) => {
					// console.log(dt);
					if (dt.data_penduduk != null) {
						data_detail_sumber_air.push({
							no: index,
							nik: dt.no_kk,
							nama: dt.data_penduduk.nama,
							jenis: dt.data_sumber.nama
						});
					}
				});
				// console.log(data_detail_sumber_air);

				this.state.data_detail_sumber_air = data_detail_sumber_air;
				this.state.judul = 'Data Potensi ' + table;
				this.state.status.dataPotensi = true;
				this.forceUpdate();
			}
		});

		// console.log(table);
		// get data for table
		// get all kategori
	};

	render() {
		// DATA JUMLAH PENDUDUK
		var data_potensi_penduduk = this.state.data_potensi_penduduk;
		// =====================================================================
		// console.log("Data USIA")
		// console.log(this.state.data_usia)
		// console.log("DAta Cek Usia")
		// console.log(this.state.cek_data_usia)
		// =====================================================================
		// console.log(this.state.produk_tahun);
		// console.log(this.state.detail_produk_desa);
		const { SearchBar } = Search;

		const columns_sumber_air = [
			{
				dataField: 'no',
				text: 'No. ',
				sort: true,
				formatter: (cell, row, rowIndex) => {
					return rowIndex + 1;
				}
			},
			{
				dataField: 'nik',
				text: 'NIK',
				sort: true
			},
			{
				dataField: 'nama',
				text: 'Nama',
				sort: true
			},
			{
				dataField: 'jenis',
				text: 'Jenis',
				sort: true
			}
		];

		return (
			<div>
				{/*Container-fluid starts*/}
				<Breadcrumb title="Dashboard" parent="Admin" />
				{/*Container-fluid Ends*/}

				{/*Container-fluid starts*/}
				<div className="container-fluid">
					<div className="row">
						{/* ===========================================						 */}
						<div className="col-xl-12 col-lg-12">
							<div className="card height-equal equal-height-lg">
								<div style={{ padding: 15 }}>
									<form>
										<div className="row">
											<div className="col-lg-9">
												<select
													className="form-control slct"
													name="id_dropdown"
													value={this.state.id_dropdown.value}
													onChange={this.ChangeDropdown}
												>
													<option value="all">{this.state.labelDropdown}</option>
													{this.state.dropdownData.map((e, key) => {
														return <option key={key} value={e.id}>{e.nama}</option>;
													})}
												</select>
											</div>
											<div className="col-lg-3" align="right">
												<button
													onClick={(e) => this.onSubmitDropdown(e)}
													color="primary"
													className="btn btn-primary"
												>
													Cari Data
															</button>
											</div>
										</div>
									</form>
									<br />
									<Row>
										<Col sm="4">
											<div className="card o-hidden">
												<div className="bg-success b-r-4 card-body">
													<div className="media static-top-widget">
														<div className="align-self-center text-center">
															<i className="icofont icofont-users-social" />
														</div>
														<div className="media-body">
															<span className="m-0">Jumlah Penduduk</span>
															<h4 className="counter mb-0">
																{/* <CountUp end={500} /> */}
																<span>{data_potensi_penduduk.total}</span>
															</h4>
															<i className="icofont icofont-users-social icon-bg" />
														</div>
													</div>
												</div>
											</div>
										</Col>
										<Col sm="4">
											<div className="card o-hidden">
												<div className="bg-info b-r-4 card-body">
													<div className="media static-top-widget">
														<div className="align-self-center text-center">
															<i className="icofont icofont-business-man" />
														</div>
														<div className="media-body">
															<span className="m-0">
																Jumlah Penduduk Laki-laki
																		</span>
															<h4 className="counter mb-0">
																<span>
																	{data_potensi_penduduk.laki_laki}
																</span>
															</h4>
															<i className="icofont icofont-business-man icon-bg" />
														</div>
													</div>
												</div>
											</div>
										</Col>
										<Col sm="4">
											<div className="card o-hidden">
												<div className="bg-danger b-r-4 card-body">
													<div className="media static-top-widget">
														<div className="align-self-center text-center">
															<i className="icofont icofont-girl" />
														</div>
														<div className="media-body">
															<span className="m-0">
																Jumlah Penduduk Perempuan
																		</span>
															<h4 className="counter mb-0">
																<span>
																	{data_potensi_penduduk.perempuan}
																</span>
															</h4>
															<i className="icofont icofont-girl icon-bg" />
														</div>
													</div>
												</div>
											</div>
										</Col>
									</Row>
									{/* ======================================================== */}
									<Row>
										<Col sm="4">
											<div className="card">
												<div className="card-body chart-block p-2">
													{
														this.state.data_agama == '' || this.state.data_agama == null || this.state.data_agama == []
															?
															<div>
																<br /><br />
																<h6 align="center"><b>Data Agama Masyarakat</b></h6>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h6 align="center"><b>Data Agama Masyarakat</b></h6>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartPieFunction(
																		this.state.data_agama,
																		this.state.drilldown_data_agama
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
										<Col sm="4">
											<div className="card">
												<div className="card-body chart-block p-2">
													{
														this.state.data_pekerjaan == '' || this.state.data_pekerjaan == null || this.state.data_pekerjaan == []
															?
															<div>
																<br /><br />
																<h6 align="center"><b>Data Pekerjaan Masyarakat</b></h6>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h6 align="center"><b>Data Pekerjaan Masyarakat</b></h6>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartPieFunction(
																		this.state.data_pekerjaan,
																		this.state.drilldown_data_pekerjaan
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>

										<Col sm="4">
											<div className="card">
												<div className="card-body chart-block p-2">
													{
														this.state.cek_data_usia.balita == 0 && this.state.cek_data_usia.dewasa_akhir == 0 && this.state.cek_data_usia.dewasa_awal == 0 && this.state.cek_data_usia.kanak_kanak == 0 && this.state.cek_data_usia.lansia_akhir == 0 && this.state.cek_data_usia.lansia_awal == 0 && this.state.cek_data_usia.manula == 0 && this.state.cek_data_usia.remaja_akhir == 0 && this.state.cek_data_usia.remaja_awal == 0
															?
															<div>
																<br /><br />
																<h6 align="center"><b>Data Rentang Usia Masyarakat</b></h6>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h6 align="center"><b>Data Rentang Usia Masyarakat</b></h6>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartPieFunction(
																		this.state.data_usia,
																		this.state.drilldown_data_usia
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
									</Row>
									{/* ====================================================== */}
									<Row>
										<Col sm="4">
											<div className="card">
												<div className="card-body chart-block p-2">
													{
														this.state.data_pernikahan == '' || this.state.data_pernikahan == null || this.state.data_pernikahan == []
															?
															<div>
																<br /><br />
																<h6 align="center"><b>Data Status Perkawinan Masyarakat</b></h6>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h6 align="center"><b>Data Status Perkawinan Masyarakat</b></h6>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartDonutFunction(
																		this.state.data_pernikahan,
																		this.state.drilldown_data_pernikahan
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
										<Col sm="4">
											<div className="card">
												<div className="card-body chart-block p-2">
													{
														this.state.data_air_minum == '' || this.state.data_air_minum == null || this.state.data_air_minum == []
															?
															<div>
																<br /><br />
																<h6 align="center"><b>Data Sumber Air Minum Masyarakat</b></h6>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h6 align="center"><b>Data Sumber Air Minum Masyarakat</b></h6>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartDonutFunction(
																		this.state.data_air_minum,
																		this.state.drilldown_data_air_minum
																	)}
																/>

																<div align="center">
																	<button
																		className="btn btn-sm btn-info"
																		onClick={() =>
																			this.getDataSumberAir('Sumber Air Minum')}
																	>
																		<span>Lihat data</span>
																	</button>
																</div>
																<br />
															</div>
													}
												</div>
											</div>
										</Col>
										<Col sm="4">
											<div className="card">
												<div className="card-body chart-block p-2">
													{
														this.state.data_kepemilikan_rumah == '' || this.state.data_kepemilikan_rumah == null || this.state.data_kepemilikan_rumah == []
															?
															<div>
																<br /><br />
																<h6 align="center"><b>Data Kepemilikan Rumah Masyarakat</b></h6>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h6 align="center"><b>Data Kepemilikan Rumah Masyarakat</b></h6>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartDonutFunction(
																		this.state.data_kepemilikan_rumah,
																		this.state.drilldown_data_kepemilikan_rumah
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
									</Row>

									{/* ====================================================== */}
									<div
										className="row"
										style={{ display: 'flex', justifyContent: 'center' }}
									>
										<Col sm="12">
											<div className="card">
												<div className="card-body chart-block p-2">
													{
														this.state.data_kategori_usaha == '' || this.state.data_kategori_usaha == null || this.state.data_kategori_usaha == []
															?
															<div>
																<br /><br />
																<h4 align="center"><b>Data Kategori Usaha Masyarakat</b></h4>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h4 align="center"><b>Data Kategori Usaha Masyarakat</b></h4>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_kategori_usaha
																	)}
																/>
															</div>
													}

												</div>
											</div>
										</Col>
									</div>
									{/* ====================================================== */}
									<Row>
										<Col sm="12">
											<div className="card">
												<div className="card-body chart-block">
													{
														this.state.data_kepemilikan_lahan == '' || this.state.data_kepemilikan_lahan == null || this.state.data_kepemilikan_lahan == []
															?
															<div>
																<br /><br />
																<h4 align="center"><b>Data Kepemilikan Lahan Masyarakat</b></h4>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h4 align="center"><b>Data Kepemilikan Lahan Masyarakat</b></h4>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_kepemilikan_lahan,
																		this.state.drilldown_data_kepemilikan_lahan
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
									</Row>
									{/* ====================================================== */}
									<Row>
										<Col sm="12">
											<div className="card">
												<div className="card-body chart-block">
													{
														this.state.data_detail_produksi == '' || this.state.data_detail_produksi == null || this.state.data_detail_produksi == []
															?
															<div>
																<br /><br />
																<h4 align="center"><b>Data Komoditas Masyarakat</b></h4>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h4 align="center"><b>Data Komoditas Masyarakat</b></h4>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_detail_produksi,
																		this.state.drilldown_detail_produksi
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
									</Row>
									{/* ====================================================== */}
									<Row>
										<Col sm="12">
											<div className="card">
												<div className="card-body chart-block">
													{
														this.state.data_aset == '' || this.state.data_aset == null || this.state.data_aset == []
															?
															<div>
																<br /><br />
																<h4 align="center"><b>Data Aset Masyarakat</b></h4>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h4 align="center"><b>Data Aset Masyarakat</b></h4>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_aset,
																		this.state.drilldown_data_aset
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
									</Row>
									{/* ====================================================== */}
									<Row>
										<Col sm="12">
											<div className="card">
												<div className="card-body chart-block">
													{
														this.state.data_lembaga_ekonomi == '' || this.state.data_lembaga_ekonomi == null || this.state.data_lembaga_ekonomi == []
															?
															<div>
																<br /><br />
																<h4 align="center"><b>Data Lembaga Ekonomi Masyarakat</b></h4>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h4 align="center"><b>Data Lembaga Ekonomi Masyarakat</b></h4>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_lembaga_ekonomi
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
									</Row>
									{/* ====================================================== */}
									<Row>
										<Col sm="12">
											<div className="card">
												<div className="card-body chart-block">
													{
														this.state.data_produksi_galian == '' || this.state.data_produksi_galian == null || this.state.data_produksi_galian == []
															?
															<div>
																<br /><br />
																<h4 align="center"><b>Data Produksi Galian Masyarakat</b></h4>
																<br />
																<h5 align="center">Maaf, Data Belum Ditambahkan</h5>
																<br /><br />
															</div>
															:
															<div>
																<br />
																<h4 align="center"><b>Data Produksi Galian Masyarakat</b></h4>
																<HighchartsReact
																	highcharts={Highcharts}
																	options={this.highchartStatisticFunction(
																		this.state.data_produksi_galian,
																		this.state.drilldown_data_produksi_galian
																	)}
																/>
															</div>
													}
												</div>
											</div>
										</Col>
									</Row>
									{/* ====================================================== */}

								</div>
							</div>
						</div>
						{/* =============================================== */}

					</div>
				</div>
				{/*Container-fluid Ends*/}


				<Modal
					open={this.state.status.dataPotensi}
					styles={{ modal: { width: '80%' } }}
					onClose={this.tutupForm}
					closeOnEsc={true}
					closeOnOverlayClick={true}
				>
					<div className="modal-header">
						<h5 className="modal-title">{this.state.judul}</h5>
					</div>
					<div className="modal-body">
						<ToolkitProvider
							data={this.state.data_detail_sumber_air}
							keyField="nik"
							columns={columns_sumber_air}
							exportCSV
							search
						>
							{(props) => (
								<div>
									<div className="card-body datatable-react">
										<div className="row">
											<div className="col-sm-4">
												<SearchBar {...props.searchProps} />
											</div>
											<div className="col-sm-4" />
											<div className="col-sm-4">
												<Select
													// isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														let data_detail_sumber_air = [];
														let url = '';
														let id_fill = this.state.akun.sub.data_kecamatan.id
														let role_log = "kecamatan"

														if (e != null) {
															url = 'data-grafik/' + role_log + '/' + id_fill + '/sumber-air-minum-masyarakat/' + e.value;
														} else {
															url = 'data-grafik/' + role_log + '/' + id_fill + '/sumber-air-minum-masyarakat';
														}

														Get(url, null, (data) => {
															data.result.map((dt, index) => {
																// console.log(dt);
																if (dt.data_penduduk != null) {
																	data_detail_sumber_air.push({
																		no: index,
																		nik: dt.no_kk,
																		nama: dt.data_penduduk.nama,
																		jenis: dt.data_sumber.nama
																	});
																}
															});

															this.state.data_detail_sumber_air = data_detail_sumber_air;
															this.state.judul = 'Data Potensi Sumber Air Minum';
															this.state.filterValue = e;
															this.state.status.dataPotensi = true;
															this.forceUpdate();
														});
													}}
													defaultValue={this.state.filterValue}
													value={this.state.filterValue}
													name="role"
													options={this.state.option_jenis_sumber_air}
													placeholder="Jenis Sumber Air Minum"
													isClearable
													required
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-12" style={{ marginBottom: '0.5rem' }}>
												<ExportCSVButton {...props.csvProps}> Export CSV </ExportCSVButton>
											</div>
										</div>

										<BootstrapTable
											{...props.baseProps}
											keyField="nik"
											data={this.state.data_detail_sumber_air}
											columns={columns_sumber_air}
											// fetchInfo={{ dataTotalSize: this.state.data_detail_sumber_air.length }}
											pagination={paginationFactory()}
										/>
									</div>
								</div>
							)}
						</ToolkitProvider>
					</div>
				</Modal>
			</div>
		);
	}
}

export default Default;
